<template>
  <div
    v-loading.fullscreen.lock="pageLoading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  ></div>
</template>

<script>
import { getUrlQuery } from '@/utils/qs.js';
import { setItem, clearAll } from '@/utils/storage.js';
import jumpSmartPark from '@/utils/jump-smartpark.js';
// api
import { getLoginUser } from '@/api/common/index.js';
export default {
  data() {
    return {
      pageLoading: false,
      loadingText: '系统跳转中...'
    };
  },
  created() {
    this.queryHandler();
  },
  methods: {
    queryHandler() {
      // const { token, appInfo, tenantInfo, appType, orgId } = getUrlQuery();
      const { token, appInfo, tenantInfo, orgId } = getUrlQuery();
      if (token) {
        setItem('token', token);
        setItem('orgId', orgId);
        setItem('appInfo', appInfo);
        // setItem('appType', appType);
        setItem('appType', 1);
        setItem('tenantInfo', tenantInfo); // 租户
        // this.getLoginUserData(appType);
        this.getLoginUserData(1);
      } else {
        clearAll();
        jumpSmartPark();
      }
    },
    // 获取登录用户信息
    getLoginUserData(appType) {
      this.pageLoading = true;
      getLoginUser({ appType })
        .then(res => {
          setItem('userInfo', res.data);
          this.handleMenu(res.data.menuList, res.data.adminType, res.data.appType);
        })
        .catch(err => {
          this.$notify({
            title: '提示：',
            message: '登录失败，请联系管理员！',
            type: 'warning',
            duration: 3000,
            onClose: () => {
              clearAll();
              jumpSmartPark();
            }
          });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    handleMenu(menuList = [], adminType, appType) {
      // adminType:  0-子系统管理员 1-普通用户 2-子系统超级管理员
      // appType:  1-安全 2-环保 3-职业健康 4-协同办公 5-技术管理 6-质量管理 7-科研管理 
      const { routes } = this.$router.options;
      let asideMenu;
      if (adminType === 2) {
        /* 超管 */
        asideMenu = routes.filter(item => item.meta.roleType === 'super');
      } else {
        // if (adminType === 0) {
        /* 子系统管理员 */
        //   asideMenu = routes.filter(item => item.meta.roleType === 'common');
        //   const permissionCodeList = menuList.map(item => item.code);
        //   setItem('permissionCodeList', permissionCodeList);
        // } else if (adminType === 1) {

        // let meta_code = appType === 1 ? 'security' : appType === 2 ? 'environment' : appType === 3 ? 'health' : 'OA';
        let meta_code =''
        switch (appType) {
          case 1:
            meta_code ='security';
            break;
          case 2:
            meta_code ='environment';
            break;
          case 3:
            meta_code ='health';
            break;
          case 4:
            meta_code ='OA';
            break;
          case 5:
            meta_code ='technical';
            break;
          case 6:
            meta_code ='quality';
            break;
          case 7:
            meta_code ='scientific';
            break;
          default:
            break;
        }
        const newRoutes = routes.filter(item => item.meta.roleType === meta_code);
        const permissionCodeList = menuList.map(item => item.code);

        const parentObj = this.getParentObj(newRoutes);
        const list = permissionCodeList;
        permissionCodeList.forEach(item => {
          list.push(...this.getNewList(parentObj, item));
        });
        const newList = [...new Set(list)];
        setItem('permissionCodeList', newList);
        asideMenu = this.filterPermissionMenu(newRoutes, newList);
        // 如果仅有 按钮权限
        if (asideMenu.length === 0) {
          this.$notify({
            title: '提示：',
            message: '暂未分配用户权限，请联系管理员！',
            type: 'warning',
            duration: 3000,
            onClose: () => {
              clearAll();
              jumpSmartPark();
            }
          });
          return;
        }
        // }
      }

      //  去除 meta.menu === false 的项
      const newMenu = this.filterAsideMenu(asideMenu);
      setItem('asideMenu', newMenu);
      this.$router.push(asideMenu[0].children[0] ? asideMenu[0].children[0].path : asideMenu[0].path);
    },
    filterPermissionMenu(tree, list = []) {
      const newTree = tree.filter(item => {
        if (item.children?.length) {
          item.children = this.filterPermissionMenu(item.children, list);
        }
        return list.includes(item.meta.code);
      });

      return newTree;
    },
    // 构造父子 code 键值对 对象 { code: pcode }
    getParentObj(tree, pcode, obj = {}) {
      tree.forEach(item => {
        if (item.meta.code && pcode) {
          obj[item.meta.code] = pcode;
        }
        if (item.children?.length) {
          this.getParentObj(item.children, item.meta.code, obj);
        }
      });
      return obj;
    },
    // 构造新的权限 code list
    getNewList(obj, target, result = []) {
      if (obj[target]) {
        result.push(obj[target]);
        this.getNewList(obj, obj[target], result);
      }
      return result;
    },
    filterAsideMenu(list = []) {
      // 如果 menu 为 false， 则直接去掉该级
      // 如果上级 menu 为 true, 则保留子级 menu 为 true 的项
      const newList = list.filter(item => item.meta.menu);
      newList.forEach(item => {
        if (item.children?.length) {
          item.children = this.filterAsideMenu(item.children);
        }
      });
      return newList;
    }
  }
};
</script>
