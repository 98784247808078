const { parse, stringify } = require('qs');

/**
 * 获取 url 携带参数，参数对象
 * @returns {Object}
 */
export function getUrlQuery() {
  return parse(window.location.href.split('?')[1]);
};
/**
 * 将对象转换为 query 形式字符串
 * @returns {String}
 */
export function stringifyQuery(query) {
  return stringify(query);
};
